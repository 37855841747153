<template>
<div class="modal fade" id="orderJobNoteCreateModal" tabindex="-1" role="dialog" aria-labelledby="orderJobNoteCreateModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Add Note</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">

                        <div class="col-12">
                            <div class="form-group">
                                <label>Note </label>
                                <textarea v-model="note" class="form-control" rows="5" placeholder="Note"></textarea>
                            </div>
                        </div>
                        <div class="col-12 form-group" v-if="userType && userType?.length > 1 ">
                            <label>User Type</label>
                            <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="userType" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="createJobNoteHandler">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Add Note</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
    name: "AddOrderJobNote",
    mixins: [ShowToastMessage, Loader],
    props: ['currentOrderJob'],
    emits: ['getOrderJobsList'],
    data() {
        return {
            oderJobId: null,
            note: '',
            selectedUserType: null,

        }
    },
    computed: {
        ...mapGetters({
            userType: 'appUsers/userType',
            settingOrderJobStatus: 'appSettings/settingOrderJobStatus',
            orderJobNote: 'appOrderJobNotes/orderJobNote',

        }),
    },
    watch: {
        currentOrderJob(currentOrderJob) {
            this.note = '';
            this.oderJobId = currentOrderJob ?.id ?? null;
            this.selectedUserType = null;

        }
    },
    methods: {
        ...mapActions({
            postOrderJobNote: 'appOrderJobNotes/postOrderJobNote',

        }),

        async createJobNoteHandler() {

            if (!this.note.trim()) {
                this.showToastMessage({
                    type: 'warning',
                    message: 'Please enter note'
                });
                return;
            }
            if (this.userType ?.length > 1 && !this.selectedUserType ?.name) {
                this.showToastMessage({
                    type: 'warning',
                    message: 'Please select user type'
                });
                return;
            }

            this.loader(true);
            const orderJobNoteData = {
                order_job_id: this.oderJobId,
                note: this.note,
                user_type: this.userType ?.length === 1 ? this.userType[0].value : this.selectedUserType.value,
                user_id: this.$store.getters["appAuthLogin/authUser"].id,
            }
            const response = await this.postOrderJobNote(orderJobNoteData);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 201 && this.orderJobNote ?.id) {
                this.showToastMessage({
                    type: 'success',
                    message: 'Job note created successfully'
                });
                
                this.$emit('getOrderJobsList');
                document.querySelector('[data-target="#orderJobNoteCreateModal"]').click();
                this.resetData();
            }

        },
        resetData() {
            this.note = '';
            this.oderJobId = null;
            this.selectedUserType = null;
        }

    }

}
</script>

<style>

      </style>
