<template>
<div class="modal fade" id="updateOrderJobStatusModal" tabindex="-1" role="dialog" aria-labelledby="updateOrderJobStatusModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Status</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Status</label>
                                <VueMultiselect v-model="selectedStatus" class="" placeholder="select assigne" :options="settingOrderJobStatus?.value ?? []" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateAssignee">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Update</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
    name: "UpdateOrderJobStatusModal",
    mixins: [ShowToastMessage, Loader],
    props: ['order'],
    emits: ['getOrderJobsList'],

    data() {
        return {
            assigneeOptions: [],
            selectedStatus: null,
        }
    },
    computed: {
        ...mapGetters({
            orderJob: 'appOrderJobs/orderJob',
            settingOrderJobStatus: 'appSettings/settingOrderJobStatus',
        }),

    },
    watch: {
        orderJob(currentOrderJob) {
            if (currentOrderJob ?.id) {
                this.resetData();
               
            }

        }
    },
    methods: {
        ...mapActions({
            putOrderJob: 'appOrderJobs/putOrderJob',
        }),
        getTechnicianList() {
            return this.$store.getters['appUsers/users'].map(item => {
                let technicianUserId = item.id;
                let firstName = item.first_name;
                let lastName = item.last_name;
                let fullName = firstName;
                fullName += firstName ? ` ${lastName}` : lastName;
                return {
                    value: technicianUserId,
                    name: fullName,
                };
            })
        },
        async updateAssignee() {
            if (!this.selectedStatus ?.name) {
                this.showToastMessage({
                    type: 'warning',
                    message: 'Please select assigne'
                });
                return;
            }

            const dataObj = {
                id: this.orderJob.id,
                data: {
                    status: this.selectedStatus.value,
                }
            }
            this.loader(true);
            const response = await this.putOrderJob(dataObj);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 200) {
                this.showToastMessage({
                    type: 'success',
                    message: 'Updated successfully'
                });
                this.$emit('getOrderJobsList');
                document.querySelector('[data-target="#updateOrderJobStatusModal"]').click();
                this.resetData();
            }

        },
        resetData() {
            this.selectedStatus = null;

        }

    }

}
</script>

    
<style>

          </style>
