<template>
<div class="modal fade " id="viewJobNotesModal" tabindex="-1" role="dialog" aria-labelledby="viewJobNotesModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Notes</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row d-flex justify-content-center">

                        <div class="col-12 col-md-12" v-for="(orderJobNote,index) in orderJobNotes" :key="index">
                            <div class="d-flex ">
                                <div class="" style="flex-shrink: 0" v-if="orderJobNote?.user?.avatar">
                                    <img class="rounded-avatar" :src="orderJobNote.user.avatar" alt="">
                                </div>

                                <div class="ml-1">
                                    <h5 v-if="orderJobNote?.user?.full_name && orderJobNote?.user_type" class="list-title mb-0 font-weight-bold">{{orderJobNote.user.full_name}} <small class="text-italic font-weight-normal">as {{orderJobNote.user_type}}</small></h5>
                                    <div class="py-1" v-if="orderJobNote.created_at">
                                        <small class="text-italic font-weight-normal d-block">Created : <span class="text-dark font-weight-bold">{{orderJobNote.created_at}}</span></small>
                                    </div>
                                    <p class="font-small-3">{{orderJobNote.note}}</p>
                                </div>
                            </div>
                            <hr>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";

export default {
    name: "ViewJobNotesModal",
    computed: {
        ...mapGetters({
            orderJobNotes: 'appOrderJobNotes/orderJobNotes',
        }),
    },
};
</script>

<style scoped>
.rounded-avatar {
    overflow: hidden;
    width: 60px;
    border-radius: 50% !important;
    border-radius: 50% !important;
    border: 2px solid #7698d9 !important;
    border-bottom: none !important;
    border-left: none !important;
    /* border-top: none  !important; */
}
</style>
